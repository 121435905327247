.header {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 2;
    background-color: var(--accent-color);
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    max-width: 1280px;
}

.container h1 {
    color: #fff;
    font-weight: 600;
}

.profile {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.profile:hover {
    transform: scale(1.1);
}

.accIcon {
    position: relative;
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 40px;
    right: 0;
    width: 185px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 0;
    z-index: 3;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 500;
    background-color: #fff;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    transition: all 0.3s ease-in-out;
}

.modal button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.modal button,
.modal a {
    color: var(--text-color) !important;
}

.overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
}

.logo {
    height: 26px;
}

.bannerText {
    font-weight: 500;
    color: #fff;
}

.bannerButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.bannerButton {
    background-color: #fff;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.bannerButton:hover {
    background-color: rgba(255, 255, 255, 0.75);
}
