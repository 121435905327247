.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 95vh;
    background-color: var(--background-color);
}

.main i {
    font-size: 2.4rem;
}

.main h1 {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
    margin: 0.6rem 0;
}

.main p {
    text-align: center;
    font-size: 1.2rem;
}

.loading {
    animation: loading 0.7s infinite linear;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.check {
    font-size: 3rem !important;
}