.signup {
    margin: auto 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 45px 40px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 16px rgb(0 0 0 / 15%);
}

.signup form {
    width: 100%;
}

.signup h1 {
    font-size: 1.8rem;
    margin-bottom: 12px;
}

.login,
.google {
    margin-top: 12px;
}

.google span {
    color: var(--accent-color);
    cursor: pointer;
}

.google span:hover {
    text-decoration: underline;
}

.divider {
    height: 1px;
    margin-top: 12px;
    width: 100%;
    background-color: #e0e0e0;
}