.card {
    position: relative;
    margin: 0.6rem 0;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1.25rem;
    background-color: #fff;
    width: 100%;
    border: 2px solid #a77bff;
}

.card:last-child {
    margin-bottom: 2rem;
}

.hidden {
    position: absolute;
    inset: 0;
    backdrop-filter: blur(3px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden button.view {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    padding: 0.8rem 1.4rem;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
}

.emoji {
    font-size: 3rem;
    margin-right: 1rem;
    user-select: none;
}

.details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.date {
    font-size: 0.85rem;
    color: #999;
    font-family: "Work Sans", sans-serif !important;
}

.text {
    font-size: 1.125rem;
    line-height: 1.2;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: "Space Grotesk", sans-serif !important;
}

.noText {
    font-size: 1.2rem;
    color: #999999;
    font-family: "Space Grotesk", sans-serif !important;
}

.delete {
    background-color: transparent;
    border: none;
    outline: none;
    align-self: center;
    font-size: 0.6rem;
    margin-left: 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.delete:hover {
    transform: scale(1.1);
}

.delete i {
    color: var(--error-color);
}

.form {
    position: relative;
    width: 100%;
}

.form span {
    display: none;
}

.subDetailsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.8rem;
}
