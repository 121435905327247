.overlay {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    width: 95%;
    max-width: 500px;
    padding: 1.6rem;
    z-index: 3;
    box-shadow: 0 4px 10px rgb(0 0 0 / 21%), 0 1px 0 rgb(0 0 0 / 5%);
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.modal form {
    width: 100%;
}

.modal h2 {
    width: 100%;
}

.modal input {
    width: 100%;
    margin: 1rem 0;
    font-size: 1rem !important;
}

.modal .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.modal .buttons button {
    width: 100px;
    margin-left: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    line-height: 18px;
    background: var(--accent-color);
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s background-color ease-in-out;
    outline: none;
    border: none;
}

.cancel {
    max-width: 80px;
    background-color: #eeeeee !important;
    color: #000000 !important;
}

.cancel:hover {
    background-color: #dddddd !important;
}