.main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #ffffffef;
    padding: 2rem 0;
    z-index: 10000000;
}

.modal {
    background-color: #fff;
    padding: 2rem;
    margin: 1rem 0;
    width: 100%;
    max-width: 450px;
    border-radius: 7px;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
    .modal {
        width: 96%;
        padding: 2rem 1.5rem;
    }
}

.field {
    margin-bottom: 1rem;
}

.field label {
    display: flex;
    justify-content: space-between;
    font-size: 0.95rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.field label a {
    color: var(--primary-color);
    text-decoration: none;
}

.field label a:hover {
    text-decoration: underline;
}

.modal button {
    margin: 0.25rem 0;
}

.divider {
    position: relative;
    margin: 1.1rem 0;
    height: 1px;
    background-color: #ccc;
}

.divider::before {
    content: "or";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0 7px;
    color: #ccc;
}

.other a {
    font-weight: 600;
    color: var(--text-color);
    text-decoration: none;
}

.other a:hover {
    text-decoration: underline;
}

.infoText {
    font-size: 1.1rem;
    line-height: 1.4;
    color: #505050;
    margin-bottom: 1rem;
}

.modal img {
    margin: 0 auto 1rem auto;
    height: 150px;
}