.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.container {
    padding: 2rem 0.8rem;
    height: 100%;
    width: 100%;
    max-width: 1200px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.main1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cards {
    height: 100%;
    width: 29%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 7px;
    padding: 2rem;
}

.cards h2 {
    margin-bottom: 1rem;
    width: 100%;
}