:root {
    --accent-color: #8a4fff;
    --text-color: #0e1116;
    --background-color: #FFFDF7;
    --success-color: #1d7874;
    --error-color: #da4167;
    --card-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body,
div#root {
    color: var(--text-color);
    background-color: var(--background-color);
    font-family: 'Work Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
}

.main-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    min-height: calc(100vh - 75px);
    max-width: 1200px;
    margin-bottom: 8.5rem;
}

.scroll-to-top {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0.75rem 1rem;
    margin-top: 0.5rem;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: var(--card-box-shadow);
    cursor: pointer;
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]),
select,
textarea {
    font-family: 'Work Sans', sans-serif;
    background: #fff;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px 12px 11px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -.006em;
    margin-bottom: 12px;
    resize: none;
    outline: none;
    transition: 0.2s ease-in-out;
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]):hover,
select:hover,
textarea:hover {
    background-color: #fefefe;
}

input:not(.custom-input):not([type=checkbox]):not([type=radio]):focus,
select:focus,
textarea:focus {
    border: 1px solid var(--accent-color);
    background-color: #fefefe;
}

input.error-input {
    border: 1px solid var(--error-color) !important;
    box-shadow: none !important;
}

.error-feedback {
    color: var(--error-color) !important;
    font-size: 0.8rem;
    margin-top: -5px;
    margin-bottom: 12px;
    text-align: left;
    width: 100%;
}

a {
    text-decoration: none;
    color: var(--accent-color);
    transition: 0.2s ease-in-out;
}

a:hover {
    text-decoration: underline;
}

.simple-container {
    width: 100%;
    max-width: 1024px;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

button.button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10px;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    background: var(--accent-color);
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.2s background-color ease-in-out;
    outline: none;
    border: none;
}

button.button:hover {
    background-color: #7c3bff;
}

button.button:disabled {
    background-color: #681cff;
    cursor: not-allowed;
}

button.button i {
    margin-right: 7px;
}

button.button i.fa-spinner-third {
    margin-right: 0;
    font-size: 18px;
    animation: 0.7s spinner infinite linear;
}

.google-auth i {
    margin-left: 7px;
    animation: 0.7s spinner infinite linear;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

button.home-btn {
    background-color: var(--accent-color);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 1rem 4rem;
    font-size: 1.2rem;
    font-weight: 600 !important;
    box-shadow: 5px 5px 4px rgb(138 79 255 / 49%);
    font-family: 'Work Sans', sans-serif !important;
    transition: all 0.3s ease-out;
    cursor: pointer;
}

button.home-btn:hover {
    font-weight: 600 !important;
    transform: scale(1.05);
}

button.home-btn a {
    color: #fff;
    text-decoration: none;
}

button.home-btn a:hover {
    color: #fff;
    text-decoration: none;
}

body:not(.homepage) .crisp-client {
    display: none;
}