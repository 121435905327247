.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
    width: 100%;
}

.hero h1 {
    font-size: 6rem;
    text-align: center;
    line-height: 0.9;
    margin-bottom: 0.4rem;
}

.hero p.tagline {
    margin: 1.2rem 0;
    font-size: 1.2rem;
    text-align: center;
}

.hero p.emojis {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.2rem;
}

.hero .plogin {
    margin-top: 1rem;
}

.hero .plogin a {
    margin-left: 5px;
}

@media screen and (max-width: 500px) {
    .hero {
        padding: 3rem 0;
    }

    .hero h1 {
        font-size: 3.8rem;
    }
}

.phBadge {
    margin-top: 2.225rem;
}