.main {
    position: fixed;
    bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    padding: 0.4rem;
    width: 95%;
    max-width: 500px;
    background-color: #fff;
    box-shadow: var(--card-box-shadow);
}

.main .row {
    margin: 5px 0;
}

.button {
    font-size: 2rem;
    border: none;
    outline: none;
    background: transparent;
    margin: 0 0.4rem;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease-in-out;
}

.button:hover {
    transform: translateY(-0.3rem);
}

@media screen and (max-width: 480px) {
    .button {
        font-size: 1.5rem;
    }
}