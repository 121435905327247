.header {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 3rem;
    max-width: 1280px;
}

.container h1 {
    font-size: 2.2rem;
    color: var(--accent-color);
}

button.button {
    padding: 0.7rem 2.4rem;
}

@media screen and (max-width: 500px) {
    button.button {
        display: none;
        visibility: hidden;
    }

    .container {
        padding: 0;
        padding-left: 1rem;
    }
}

.logo {
    height: 28px;
}