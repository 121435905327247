section.main {
    display: flex;
    min-width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2rem 1rem;
}

.head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6rem;
}

.head h1 {
    color: var(--accent-color);
    font-size: 2.4rem;
}

.header {
    width: 100%;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid #CFCFCF;
}

.header h1 {
    font-size: 2.6rem;
    margin-bottom: 0.4rem;
}

.main p {
    font-size: 1.1rem;
    line-height: 1.6;
}

.section {
    margin: 1.6rem 0;
}

.section h2 {
    font-size: 1.6rem;
    text-transform: uppercase;
}

.section p {
    margin: 1rem 0;
}

.section ul, .section ol {
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-left: 1.2rem;
}

.section li {
    margin: 0.6rem 0;
}