.month {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.toggle {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 1.175rem;
    color: #555;
    padding: 10px;
    margin: 0.1rem 0;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select: none;
}

.toggle:hover {
    background-color: #0000000a;
}

.toggle i {
    margin-right: 10px;
}

.cards {
    opacity: 0;
    width: 100%;
    animation: dropdown 0.2s ease-in-out forwards;
}

@keyframes dropdown {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}