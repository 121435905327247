.card {
    margin: 0.4rem 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    background-color: #fff;
    max-width: 95%;
    box-shadow: var(--card-box-shadow);
}

.card p {
    text-align: center;
}